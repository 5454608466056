import './distributiva_atracciones_pa.scss';

import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import React from 'react';
import { graphql } from 'gatsby';
import { Asgard } from '../src/components/Asgard/Asgard';
import { Freya } from '../src/components/Freya/Freya';
import H4 from '../src/components/Titles/TitleH4single/TitleH4single';
import Heimdall from '../src/components/Heimdall/Heimdall';
import NeoAesir from '../src/components/NeoAesir/NeoAesir';
import Layout from '../src/components/Layout/Layout';
import SEO from '../src/helpers/seo';
import Buttons from '../src/components/Buttons/Buttons';
import BlueArrow from '../src/components/BlueArrow/BlueArrow';
import Hod from '../src/components/Hod/Hod';
import tt from '../src/helpers/translation';

export const GatsbyQuery = ({ data, pathContext: { locale } }) => graphql`
  query actividades($locale: String!) {
    allActividadesTitleBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          alt
          image
          localImage {
            childImageSharp {
              fluid(quality: 100, maxWidth: 1620, maxHeight: 360) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    allActividadesMobileTitleBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          alt
          image
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    allActividadesTitle(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          name
          description
          text
        }
      }
    }
    allActividadesSeoData(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          _0 {
            title
          }
          _1 {
            description
          }
        }
      }
    }
    allActividadesSubBlock(
      filter: { lang: { eq: $locale } }
      sort: { fields: position, order: ASC }
    ) {
      edges {
        node {
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image_alt
          imgsize
          title
          description
          seeMore
          located
          features
          possibleFilters1
          possibleFilters2
          buttons {
            cta
            size
            color
            ctaText
          }
        }
      }
    }
    allActividadesBreadCrumbBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          tag
          destination_url
          name
        }
      }
    }
    allActividadesRichData(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          rich_markup {
            og_title
            rich_markup_type
            og_description
            imgRich
          }
        }
      }
    }
  }
`;

class Actividades extends React.Component {
  state = {
    once: false,
    throttleInterval: 150,
    offset: 0,
    partialVisibility: false,
    world: '',
    tipoServicio: '',
    tipoMenu: '',
    initialFilterStateMundo: true,
    initialFilterStateMenu: true,
    initialFilterStateServicios: true,
  };

  handleFilter = (name, tag) => {
    if (tag === tt('Temática', this.props.pageContext.locale)) {
      const newFilterData = { ...this.state.filterData, tipoServicio: name };
      this.setState({
        tipoServicio: name,
        initialFilterStateMenu: false,
        filterData: newFilterData,
      });
    } else if (tag === tt('Tipo de actividad', this.props.pageContext.locale)) {
      const newFilterData = { ...this.state.filterData, tipoMenu: name };
      this.setState({
        tipoMenu: name,
        initialFilterStateServicios: false,
        filterData: newFilterData,
      });
    }
  };

  resetFilter = () => {
    const newFilterData = new Object();
    this.setState({
      initialFilterStateMundo: true,
      initialFilterStateMenu: true,
      initialFilterStateServicios: true,
      filterData: newFilterData,
    });
  };

  render() {
    const heimdallData = {
      name: this.props.data.allActividadesTitle.edges[0].node.name,
      subtitle: this.props.data.allActividadesTitle.edges[0].node.description.replace(
        /<\/?[^>]+(>|$)/g,
        ''
      ),
      image: {
        alt: this.props.data.allActividadesTitleBlock.edges[0].node.alt,
        url: this.props.data.allActividadesTitleBlock.edges[0].node.localImage.childImageSharp
          .fluid,
      },
      imageMobile: {
        url: this.props.data.allActividadesMobileTitleBlock.edges[0].node.localImage.childImageSharp
          .fluid,
        alt: this.props.data.allActividadesMobileTitleBlock.edges[0].node.alt,
      },
    };

    const buttons = {
      cta: tt('/entradas', this.props.pageContext.locale),
      size: 'alone',
      color: 'orange',
      ctaText: tt('COMPRAR ENTRADAS', this.props.pageContext.locale),
    };

    const AsgardData = {
      image:
        'https://trello-attachments.s3.amazonaws.com/5e981f15302742813792129b/86x86/5f46a2a7239da9674bbba4cc2d79837f/logo-86x86.jpg',
      text: `${this.props.data.allActividadesSubBlock.edges.length} ${tt(
        'Actividades',
        this.props.pageContext.locale
      )}`,
      filters: [
        {
          tag: tt('Temática', this.props.pageContext.locale),
          options: [
            { value: tt('Halloween', this.props.pageContext.locale), label: 'halloween' },
            { value: tt('Navidad', this.props.pageContext.locale), label: 'navidad' },
            { value: tt('Mediterrània', this.props.pageContext.locale), label: 'Mediterrània' },
            { value: tt('China', this.props.pageContext.locale), label: 'China' },
            { value: tt('Polynesia', this.props.pageContext.locale), label: 'Polynesia' },
            { value: tt('México', this.props.pageContext.locale), label: 'México' },
            {
              value: tt('SésamoAventura', this.props.pageContext.locale),
              label: 'Sesamo Aventura',
            },
          ],
        },
        {
          tag: tt('Tipo de actividad', this.props.pageContext.locale),
          options: [
            { value: tt('Puzles', this.props.pageContext.locale), label: 'puzzles' },
            {
              value: tt('Colorea', this.props.pageContext.locale),
              label: 'colorea',
            },
            {
              value: tt('Diferencias', this.props.pageContext.locale),
              label: 'diferencias',
            },
            { value: tt('Maquetas', this.props.pageContext.locale), label: 'maquetas' },
            {
              value: tt('Vídeos espectáculos/atracciones', this.props.pageContext.locale),
              label: 'vídeos',
            },
            {
              value: tt('Recetas', this.props.pageContext.locale),
              label: 'recetas',
            },
            {
              value: tt('Manualidades', this.props.pageContext.locale),
              label: 'manualidades',
            },
            {
              value: tt('Historias', this.props.pageContext.locale),
              label: 'historias',
            },
          ],
        },
      ],
    };
    const freyaDataRides = {
      cards: this.props.data.allActividadesSubBlock.edges
        .map((ride) => ({
          image: ride.node.localImage.childImageSharp.fluid,
          altImage: ride.node.image_alt,
          imgsize: ride.node.imgsize,
          title: ride.node.title,
          titleType: 'h3',
          description: ride.node.description,
          seeMore: ride.node.seeMore,
          located:
            ride.node.located === 'Sesamo Aventura'
              ? 'SésamoAventura'
              : ride.node.located.charAt(0).toUpperCase() + ride.node.located.slice(1),
          features: ride.node.features,
          possibleFilters: ride.node.possibleFilters1 + ride.node.possibleFilters2,
          buttons: {
            cta: ride.node.buttons.cta,
            size: ride.node.buttons.size,
            color: ride.node.buttons.color,
            ctaText: ride.node.buttons.ctaText,
          },
        }))
        .filter((item) => {
          const setFilters = [];
          for (const prop in this.state.filterData) setFilters.push(this.state.filterData[prop]);
          if (!setFilters.length) return item;
          if (setFilters.every((elem) => item.possibleFilters.indexOf(elem) > -1)) return item;
        }),
    };

    return (
      <Layout layoutFromOld  locale={this.props.pageContext.locale} pageName={this.props.pageContext.pageName}>
        <SEO
          title={this.props.data.allActividadesSeoData.edges[0].node._0.title}
          description={this.props.data.allActividadesSeoData.edges[0].node._1.description}
          pathname={this.props.pageContext.url}
          lang={this.props.pageContext.locale}
          imageRich={this.props.data.allActividadesRichData.edges[0].node.rich_markup.imgRich}
        />
        <Hod
          data={this.props.data.allActividadesRichData.edges[0].node.rich_markup}
          url={this.props.pageContext.url}
          img={this.props.data.allActividadesRichData.edges[0].node.rich_markup.imgRich}
        />
        <div className="is-fullWidth">
          <Heimdall data={heimdallData} />
          <div className="Asgard-box">
            <Asgard
              locale={this.props.pageContext.locale}
              data={AsgardData}
              filterStatusMundo={this.state.initialFilterStateMundo}
              filterStatusTipoMenu={this.state.initialFilterStateMenu}
              filterStatusTipoServicios={this.state.initialFilterStateServicios}
              resetFilter={this.resetFilter}
              filterChange={this.handleFilter}
            />
          </div>
          <div className="general-index">
            <BlueArrow />
            <div className="rides-content">
              <NeoAesir
                locale={this.props.pageContext.locale}
                pageName={this.props.pageContext.pageName}
                title={this.props.data.allActividadesBreadCrumbBlock.edges[0].node.name}
              />
              <Freya data={freyaDataRides} />
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

/* eslint-disable react/require-default-props */
Actividades.propTypes = {
  RidesData: PropTypes.shape({
    name: PropTypes.string.isRequired,
    image: PropTypes.object.isRequired,
  }),
};
/* eslint-enable react/require-default-props */

export default Actividades;
